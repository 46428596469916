<template>
  <CModal
    :title="$t('lk.referrals.cash_modal.title')"
    centered
    :show="show"
    @update:show="$emit('update:show', $event)"
  >
    <CInput
      v-model="wallet"
      :label="$t('lk.referrals.cash_modal.wallet') + ':'"
      class="cinput mb-4"
      :is-valid="detectErrorField(errors.wallet)"
      :invalid-feedback="errors.wallet"
      @input="$delete(errors, 'wallet')"
    />
    <CInput
      :value="cost"
      ref="inputCostRef"
      :label="$t('lk.referrals.cash_modal.cash_cost') + ' (USDT):'"
      :placeholder="$t('lk.referrals.cash_modal.min') + ' 50 USDT'"
      class="cinput mb-5"
      :is-valid="detectErrorField(errors.cost)"
      :invalid-feedback="errors.cost"
      @input="inputCost"
    >
      <template #append-content>
        <span
          class="text-success page-lk-referral--input-all"
          @click="cost = balance"
        >
          {{ $t('lk.referrals.cash_modal.cash_all') }}
        </span>
      </template>
    </CInput>

    <p class="text-white font-2xl">
      <span class="text-white-50 font-lg">{{ $t('lk.referrals.cash_modal.available') }}: </span>
      {{ balance.toFixed(2) }}
      <span class="font-weight-bold text-success font-lg">USDT</span>
    </p>

    <p class="mb-0">
      {{ $t('lk.referrals.cash_modal.disclaimer') }}
    </p>

    <template v-slot:footer>
      <div class="w-100 d-flex">
        <CLoadingButtonCustom
          block
          color="success"
          class="mr-2 h-44"
          :loading="loading"
          :disabled="loading"
          @click.native="createTransaction"
        >
          {{ $t('lk.referrals.cash_modal.request_cash') }}
        </CLoadingButtonCustom>
        <CButton
          block
          color="dark"
          class="h-44 mt-0"
          :disabled="loading"
          @click="$emit('update:show', false)"
        >
          {{ $t('lk.referrals.cash_modal.cancel') }}
        </CButton>
      </div>
    </template>
  </CModal>
</template>

<script>
import axios from 'axios'
import { detectErrorField, formatErrorObject } from '@/assets/js/axios/responseErrorHandler'

export default {
  name: 'ReferralBonusesCashModal',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    balance: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    wallet: '',
    cost: null,
    loading: false,
    errors: {},
    detectErrorField,
  }),
  methods: {
    resetData() {
      this.wallet = ''
      this.cost = null
    },
    /** @param {string} cost */
    inputCost(cost) {
      this.$delete(this.errors, 'cost')
      if (/^([0-9]*)\.?([0-9]{1,2})?$/.test(cost)) {
        this.cost = cost
      } else {
        this.$refs.inputCostRef.state = this.cost
      }
    },
    async createTransaction() {
      this.loading = true
      axios
        .post('/api/v2/bonuses/cash', {
          wallet: this.wallet,
          cost: this.cost,
        })
        .then(() => {
          this.$toastr.success(this.$t('lk.referrals.cash_modal.success_requires'))
          this.$emit('success')
          this.$emit('update:show', false)
        })
        .catch((error) => {
          if (error?.response?.status === 422) {
            const errorObj = error?.response?.data?.errors
            this.errors = formatErrorObject(errorObj)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
