<template>
  <div>
    <div class="header">
      <div class="mr-auto d-flex align-items-center">
        <h1 class="title mb-0">
          {{ $t('lk.referrals.title') }}
        </h1>
      </div>
    </div>

    <div class="mb-4 page-lk-referral--body">
      <div class="w-100 overflow-hidden">
        <div class="d-flex h-100 flex-wrap flex-xl-nowrap" style="gap: 12px">
          <ReferralInfoCard class="w-100">
            <template #title>
              {{ $t('lk.referrals.referral_count') }}
            </template>
            <template #value>
              {{ referralsInfo.count }}
            </template>
            <template #post-value>
              <CIcon size="xl" class="page-lk-referral--icon-prefix" name="cil-user" />
            </template>
          </ReferralInfoCard>
          <ReferralInfoCard class="w-100">
            <template #title>
              {{ $t('lk.referrals.referral_payed_count') }}
            </template>
            <template #value>
              {{ referralsInfo.countPayed }}
            </template>
            <template #post-value>
              <CIcon size="xl" class="page-lk-referral--icon-prefix" name="cil-user" />
            </template>
          </ReferralInfoCard>
        </div>
      </div>

      <div class="app--horizontal-line"></div>

      <div class="w-100 overflow-hidden">
        <div class="d-flex h-100 flex-wrap flex-xl-nowrap" style="gap: 12px">
          <ReferralInfoCard class="w-100">
            <template #title>
              {{ $t('lk.referrals.balance') }}
            </template>
            <template #value>
              <div class="d-flex flex-column align-items-center">
                {{ cutNumberWithPrecession(referralsInfo.bonusBalance) }}
                <span class="page-lk-referral--icon-prefix page-lk-referral--icon-prefix-success">
                  USDT
                </span>
              </div>
            </template>
          </ReferralInfoCard>
          <ReferralInfoCard class="w-100">
            <template #title>
              {{ $t('lk.referrals.bonus_all_time') }}
            </template>
            <template #value>
              <div class="d-flex flex-column align-items-center">
                {{ cutNumberWithPrecession(referralsInfo.allBonus) }}
                <span class="page-lk-referral--icon-prefix page-lk-referral--icon-prefix-success">
                  USDT
                </span>
              </div>
            </template>
          </ReferralInfoCard>
        </div>
      </div>
    </div>

    <div class="mb-5 page-lk-referral--body">
      <div class="w-100">
        <p class="mb-4" style="color: var(--white); opacity: 0.6">
          {{ $t('lk.referrals.referral_link') }}:
        </p>
        <EditReferralCode v-if="showReferralCodeEdit" :code.sync="referralCode" />

        <div class="mt-2" v-html="$t('lk.referrals.referral_link_hint')" />
      </div>

      <div class="app--horizontal-line app--horizontal-line-transparent flex-shrink-0"></div>

      <div class="w-100">
        <p class="mb-4 text-center" style="color: var(--white); opacity: 0.6">
          {{ $t('lk.referrals.min_transaction') }}: 50 USDT
        </p>
        <div v-tooltip="getTooltip">
          <CButton block class="h-44" color="success" :disabled="referralsInfo.bonusBalance < 50" @click="showBonusTransfer = true">
            {{ $t('lk.referrals.create_transaction') }}
          </CButton>
        </div>
        <CButton block class="h-44 mt-3" color="dark" @click="showBonusHistory = true">
          {{ $t('lk.referrals.transaction_history') }}
        </CButton>
      </div>
    </div>

    <ReferralBonusesCashModal
      :show.sync="showBonusTransfer"
      :balance="referralsInfo.bonusBalance"
      @success="updateInfo"
    />
    <ReferralBonusesHistoryModal
      :show.sync="showBonusHistory"
    />
  </div>
</template>

<script>
import ReferralInfoCard from '@/components/lk/referrals/ReferralInfoCard';
import EditReferralCode from '@/components/lk/referrals/EditReferralCode';
import ReferralBonusesCashModal from '@/components/lk/referrals/ReferralBonusesCashModal';
import ReferralBonusesHistoryModal from '@/components/lk/referrals/ReferralBonusesHistoryModal';
import axios from 'axios';

export default {
  name: 'lk-referrals',
  components: {
    ReferralInfoCard,
    EditReferralCode,
    ReferralBonusesCashModal,
    ReferralBonusesHistoryModal
  },
  data: () => ({
    /** @type: string */
    referralCode: '',
    /** @type {{count: number, countPayed: number, bonusBalance: number, allBonus: number}} */
    referralsInfo: {
      count: 0,
      countPayed: 0,
      bonusBalance: 0,
      allBonus: 0
    },
    /** @type: boolean */
    showBonusTransfer: false,
    showReferralCodeEdit: false,
    showBonusHistory: false,
  }),
  beforeMount () {
    this.updateInfo();
  },
  computed: {
    getTooltip() {
      if (this.referralsInfo.bonusBalance  >= 50) return {content: `${this.$t('lk.referrals.info')} 50 USDT`, container: 'c-app'}
    }
  },
  methods: {
    cutNumberWithPrecession(number, precession = 2){
      let value = String(number)
      return value.includes('.') ? value.substring(0, value.indexOf('.') + precession + 1) : value
    },
    updateInfo () {
      axios.get('/api/v2/referrals/info')
           .then((res) => {
             /** @type UserReferralInfoResource */
             const data = res.data.data;
             this.referralCode = data.referral_code;
             this.showReferralCodeEdit = true;
             this.referralsInfo = {
               count: data.referrals_count,
               countPayed: data.referrals_payed,
               bonusBalance: data.bonus_balance,
               allBonus: data.bonus_all_time
             };
           });
    }
  }
};
</script>


<style lang="scss" scoped>
//@import "@/assets/scss/style";

.header {
  display: flex;
  align-items: center;

  @include media-breakpoint-down(xs) {
    display: none;
  }
  @include media-breakpoint-up(sm) {
    margin-bottom: 1.75rem;

    .title {
      font-size: 1.25rem;
    }
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 2.25rem;

    .title {
      font-size: 2.25rem;
    }
  }
}

.separator {
  margin: 2.5rem 0;
}

.icon {
  margin: 0;
}

.subactions {
  .ccol {
    display: flex;
  }

  @include media-breakpoint-down(xs) {
    .ccol {
      flex-direction: column;
      align-items: center;
    }
  }
}

.cinput /deep/ .form-control, .cbtn {
  height: 2.75rem;
}

.tg-button {
  width: 13rem;
}

.verify-message {
  font-size: 0.75rem;
}

.app {
  &--horizontal-line {
    width: 1px;
    background-color: var(--white);
    opacity: 0.1;

    &-transparent {
      background-color: transparent;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      height: 1px;
    }
  }
}

.page-lk-referral {
  &--body {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1fr 1px 1fr;

    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr;
      grid-gap: 1rem;
    }
  }

  &--icon-prefix {
    font-size: 25px;

    &-success {
      color: var(--success);
      font-weight: bold;
    }
  }

  &--input-all {
    border-bottom: dashed 1px rgba(69, 161, 100, 1);
    transition: all 200ms ease-out;
    cursor: pointer;

    &:hover {
      border-color: rgba(69, 161, 100, 0);
    }
  }
}
</style>
