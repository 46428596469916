var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _vm.isEditCode
        ? [
            _c(
              "CForm",
              {
                staticClass: "d-flex w-100",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.saveCode.apply(null, arguments)
                  },
                },
              },
              [
                _c("CInput", {
                  ref: "inputCode",
                  staticClass: "cinput mb-0 mr-2 w-100",
                  attrs: { value: _vm.code, autocomplete: "email" },
                  on: {
                    input: function ($event) {
                      return _vm.$emit("update:code", $event)
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "prepend-content",
                        fn: function () {
                          return [_c("CIcon", { attrs: { name: "cil-link" } })]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    126324668
                  ),
                }),
                _c(
                  "span",
                  { on: { click: _vm.saveCode } },
                  [
                    _c(
                      "CLoadingButtonCustom",
                      {
                        staticClass: "cbtn shadow-none mr-2",
                        attrs: {
                          color: "success",
                          loading: _vm.loading,
                          disabled: _vm.loading,
                        },
                      },
                      [
                        !_vm.loading
                          ? _c("CIcon", { attrs: { name: "cil-save" } })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "CButton",
                  {
                    attrs: { color: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.isEditCode = false
                      },
                    },
                  },
                  [_c("CIcon", { attrs: { name: "cil-reload" } })],
                  1
                ),
              ],
              1
            ),
          ]
        : [
            _c("CInput", {
              staticClass: "cinput mb-0 mr-2 w-100",
              attrs: {
                value: _vm.fullUrl,
                disabled: "",
                autocomplete: "email",
              },
              scopedSlots: _vm._u([
                {
                  key: "prepend-content",
                  fn: function () {
                    return [_c("CIcon", { attrs: { name: "cil-link" } })]
                  },
                  proxy: true,
                },
              ]),
            }),
            _c(
              "CButton",
              {
                staticClass: "mr-2",
                attrs: { color: "warning" },
                on: { click: _vm.startEdit },
              },
              [_c("CIcon", { attrs: { name: "cil-pencil" } })],
              1
            ),
            _c(
              "CButton",
              { attrs: { color: "success" }, on: { click: _vm.copyLink } },
              [_c("CIcon", { attrs: { name: "cil-copy" } })],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }