<template>
  <CModal
    :title="$t('lk.referrals.history_modal.title')"
    centered
    size="xl"
    :show="show"
    @update:show="$emit('update:show', $event)"
  >
    <CCard
      color="second-card-bg"
      class="ccard shadow-none"
    >
      <CCardBody class="ccard-body d-flex flex-column">
        <div class="table-wrap overflow-auto">
          <table class="table mb-0">
            <thead class="text-nowrap">
            <tr>
              <th v-for="(field, i) in fields" :key="i">
                  {{ field.label }}
              </th>
              <th />
            </tr>
            </thead>

            <tbody>
            <tr v-for="item in items" :key="item.id">
              <td :class="'text-' + getStatusMessage(item).color">
                {{ getStatusMessage(item).title }}
              </td>
              <td>
                {{ Math.abs(item.cost) }}
              </td>
              <td>
                {{ item.meta && item.meta.wallet || '-' }}
              </td>
              <td>
                {{ getDateMessage(item) }}
              </td>
              <td>
                <CLoadingButtonCustom
                  color="danger"
                  :loading="loading"
                  class="text-black-50"
                  :disabled="isCancelBlocked(item.status)"
                  @click.native="setStatus(item, 'canceled')"
                >
                  <CIcon class="mt-0" name="cil-x-circle" size="sm" />
                </CLoadingButtonCustom>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </CCardBody>
    </CCard>
    <template #footer-wrapper><span></span></template>
  </CModal>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

export default {
  name: 'ReferralBonusesHistoryModal',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      /** @type BonusTransferTransactionResource[] */
      items: [],
      loading: false,
    };
  },
  computed: {
    fields() {
      return [
        { key: 'status', label: this.$t('lk.referrals.table.header.status') },
        { key: 'cost', label: this.$t('lk.referrals.table.header.cash_cost') },
        { key: 'wallet', label: this.$t('lk.referrals.table.header.wallet') },
        { key: 'created_at', label: this.$t('lk.referrals.table.header.date') },
        { key: 'action', label: this.$t('lk.referrals.table.header.date')}
      ]
    },
    /**
     * @return {(item: BonusTransferTransactionResource) => {color: string, title: string}}
     */
    getStatusMessage() {
      return (item) => {
        if (item.meta && item.meta.wallet) {
          switch (item.status) {
            case 'succeeded': {
              return {
                color: 'success',
                title: this.$t('lk.referrals.status.succeeded')
              };
            }

            case 'pending': {
              return {
                color: 'white',
                title: this.$t('lk.referrals.status.pending')
              };
            }

            case 'new': {
              return {
                color: 'warning',
                title: this.$t('lk.referrals.status.new')
              };
            }

            case 'canceled': {
              return {
                color: 'danger',
                title: this.$t('lk.referrals.status.cancel')
              };
            }
          }
        } else {
          return {
            color: 'success',
            title: this.$t('lk.referrals.status.subscriptionStatus')
          }
        }
      }
    },
  },
  watch: {
    show (status) {
      if(status) {
        this.fetchItems();
      }
    }
  },
  beforeMount () {
    this.fetchItems();
  },
  methods: {
    isCancelBlocked(status) {
      return status === 'succeeded' || status === 'canceled'
    },
    setStatus(item, status) {
      const selected = [item]
      const transactions = selected
        .filter((i) => i.status !== status)
        .map(({ id }) => ({
          id,
          status,
        }));

      this.loading = true
      axios
        .post("/api/v2/bonuses/transfers/change-status", { transactions })
        .then(() => {
          this.fetchItems();
        })
        .finally(() => {
          this.loading = false
        });
    },
    fetchItems () {
      axios.get('/api/v2/bonuses/history')
           .then((res) => {
             this.items = res.data.data;
           });
    },
    /**
     * @param {BonusTransferTransactionResource} item
     * @return {string}
     */
    getDateMessage (item) {
      return moment(item.created_at).format('DD.MM.yyyy');
    }
  }
};
</script>

<style lang="scss" scoped>
.ccard {
  margin-bottom: 0;
  max-height: calc(100vh - 300px);
  max-height: calc(100dvh - 300px);

  @include media-breakpoint-down(xs) {
    .ccard-body {
      padding: 1rem 1.75rem;
    }
  }

  @include media-breakpoint-up(sm) {
    .ccard-body {
      padding: 1.25rem 1.75rem;
    }
  }

  @include media-breakpoint-up(xl) {
    margin: 0;
  }
}
</style>
