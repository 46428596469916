var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CModal",
    {
      attrs: {
        title: _vm.$t("lk.referrals.cash_modal.title"),
        centered: "",
        show: _vm.show,
      },
      on: {
        "update:show": function ($event) {
          return _vm.$emit("update:show", $event)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "w-100 d-flex" },
                [
                  _c(
                    "CLoadingButtonCustom",
                    {
                      staticClass: "mr-2 h-44",
                      attrs: {
                        block: "",
                        color: "success",
                        loading: _vm.loading,
                        disabled: _vm.loading,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.createTransaction.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("lk.referrals.cash_modal.request_cash")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "CButton",
                    {
                      staticClass: "h-44 mt-0",
                      attrs: {
                        block: "",
                        color: "dark",
                        disabled: _vm.loading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("update:show", false)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("lk.referrals.cash_modal.cancel")) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("CInput", {
        staticClass: "cinput mb-4",
        attrs: {
          label: _vm.$t("lk.referrals.cash_modal.wallet") + ":",
          "is-valid": _vm.detectErrorField(_vm.errors.wallet),
          "invalid-feedback": _vm.errors.wallet,
        },
        on: {
          input: function ($event) {
            return _vm.$delete(_vm.errors, "wallet")
          },
        },
        model: {
          value: _vm.wallet,
          callback: function ($$v) {
            _vm.wallet = $$v
          },
          expression: "wallet",
        },
      }),
      _c("CInput", {
        ref: "inputCostRef",
        staticClass: "cinput mb-5",
        attrs: {
          value: _vm.cost,
          label: _vm.$t("lk.referrals.cash_modal.cash_cost") + " (USDT):",
          placeholder: _vm.$t("lk.referrals.cash_modal.min") + " 50 USDT",
          "is-valid": _vm.detectErrorField(_vm.errors.cost),
          "invalid-feedback": _vm.errors.cost,
        },
        on: { input: _vm.inputCost },
        scopedSlots: _vm._u([
          {
            key: "append-content",
            fn: function () {
              return [
                _c(
                  "span",
                  {
                    staticClass: "text-success page-lk-referral--input-all",
                    on: {
                      click: function ($event) {
                        _vm.cost = _vm.balance
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("lk.referrals.cash_modal.cash_all")) +
                        " "
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("p", { staticClass: "text-white font-2xl" }, [
        _c("span", { staticClass: "text-white-50 font-lg" }, [
          _vm._v(_vm._s(_vm.$t("lk.referrals.cash_modal.available")) + ": "),
        ]),
        _vm._v(" " + _vm._s(_vm.balance.toFixed(2)) + " "),
        _c("span", { staticClass: "font-weight-bold text-success font-lg" }, [
          _vm._v("USDT"),
        ]),
      ]),
      _c("p", { staticClass: "mb-0" }, [
        _vm._v(
          " " + _vm._s(_vm.$t("lk.referrals.cash_modal.disclaimer")) + " "
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }