var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "mr-auto d-flex align-items-center" }, [
          _c("h1", { staticClass: "title mb-0" }, [
            _vm._v(" " + _vm._s(_vm.$t("lk.referrals.title")) + " "),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "mb-4 page-lk-referral--body" }, [
        _c("div", { staticClass: "w-100 overflow-hidden" }, [
          _c(
            "div",
            {
              staticClass: "d-flex h-100 flex-wrap flex-xl-nowrap",
              staticStyle: { gap: "12px" },
            },
            [
              _c("ReferralInfoCard", {
                staticClass: "w-100",
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("lk.referrals.referral_count")) +
                            " "
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "value",
                    fn: function () {
                      return [
                        _vm._v(" " + _vm._s(_vm.referralsInfo.count) + " "),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "post-value",
                    fn: function () {
                      return [
                        _c("CIcon", {
                          staticClass: "page-lk-referral--icon-prefix",
                          attrs: { size: "xl", name: "cil-user" },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("ReferralInfoCard", {
                staticClass: "w-100",
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("lk.referrals.referral_payed_count")
                            ) +
                            " "
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "value",
                    fn: function () {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.referralsInfo.countPayed) + " "
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "post-value",
                    fn: function () {
                      return [
                        _c("CIcon", {
                          staticClass: "page-lk-referral--icon-prefix",
                          attrs: { size: "xl", name: "cil-user" },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "app--horizontal-line" }),
        _c("div", { staticClass: "w-100 overflow-hidden" }, [
          _c(
            "div",
            {
              staticClass: "d-flex h-100 flex-wrap flex-xl-nowrap",
              staticStyle: { gap: "12px" },
            },
            [
              _c("ReferralInfoCard", {
                staticClass: "w-100",
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.$t("lk.referrals.balance")) + " "
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "value",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-column align-items-center",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.cutNumberWithPrecession(
                                    _vm.referralsInfo.bonusBalance
                                  )
                                ) +
                                " "
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "page-lk-referral--icon-prefix page-lk-referral--icon-prefix-success",
                              },
                              [_vm._v(" USDT ")]
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("ReferralInfoCard", {
                staticClass: "w-100",
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("lk.referrals.bonus_all_time")) +
                            " "
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "value",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-column align-items-center",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.cutNumberWithPrecession(
                                    _vm.referralsInfo.allBonus
                                  )
                                ) +
                                " "
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "page-lk-referral--icon-prefix page-lk-referral--icon-prefix-success",
                              },
                              [_vm._v(" USDT ")]
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "mb-5 page-lk-referral--body" }, [
        _c(
          "div",
          { staticClass: "w-100" },
          [
            _c(
              "p",
              {
                staticClass: "mb-4",
                staticStyle: { color: "var(--white)", opacity: "0.6" },
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("lk.referrals.referral_link")) + ": "
                ),
              ]
            ),
            _vm.showReferralCodeEdit
              ? _c("EditReferralCode", {
                  attrs: { code: _vm.referralCode },
                  on: {
                    "update:code": function ($event) {
                      _vm.referralCode = $event
                    },
                  },
                })
              : _vm._e(),
            _c("div", {
              staticClass: "mt-2",
              domProps: {
                innerHTML: _vm._s(_vm.$t("lk.referrals.referral_link_hint")),
              },
            }),
          ],
          1
        ),
        _c("div", {
          staticClass:
            "app--horizontal-line app--horizontal-line-transparent flex-shrink-0",
        }),
        _c(
          "div",
          { staticClass: "w-100" },
          [
            _c(
              "p",
              {
                staticClass: "mb-4 text-center",
                staticStyle: { color: "var(--white)", opacity: "0.6" },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("lk.referrals.min_transaction")) +
                    ": 50 USDT "
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.getTooltip,
                    expression: "getTooltip",
                  },
                ],
              },
              [
                _c(
                  "CButton",
                  {
                    staticClass: "h-44",
                    attrs: {
                      block: "",
                      color: "success",
                      disabled: _vm.referralsInfo.bonusBalance < 50,
                    },
                    on: {
                      click: function ($event) {
                        _vm.showBonusTransfer = true
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("lk.referrals.create_transaction")) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "CButton",
              {
                staticClass: "h-44 mt-3",
                attrs: { block: "", color: "dark" },
                on: {
                  click: function ($event) {
                    _vm.showBonusHistory = true
                  },
                },
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("lk.referrals.transaction_history")) + " "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("ReferralBonusesCashModal", {
        attrs: {
          show: _vm.showBonusTransfer,
          balance: _vm.referralsInfo.bonusBalance,
        },
        on: {
          "update:show": function ($event) {
            _vm.showBonusTransfer = $event
          },
          success: _vm.updateInfo,
        },
      }),
      _c("ReferralBonusesHistoryModal", {
        attrs: { show: _vm.showBonusHistory },
        on: {
          "update:show": function ($event) {
            _vm.showBonusHistory = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }