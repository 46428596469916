var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CModal",
    {
      attrs: {
        title: _vm.$t("lk.referrals.history_modal.title"),
        centered: "",
        size: "xl",
        show: _vm.show,
      },
      on: {
        "update:show": function ($event) {
          return _vm.$emit("update:show", $event)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer-wrapper",
          fn: function () {
            return [_c("span")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "CCard",
        {
          staticClass: "ccard shadow-none",
          attrs: { color: "second-card-bg" },
        },
        [
          _c("CCardBody", { staticClass: "ccard-body d-flex flex-column" }, [
            _c("div", { staticClass: "table-wrap overflow-auto" }, [
              _c("table", { staticClass: "table mb-0" }, [
                _c("thead", { staticClass: "text-nowrap" }, [
                  _c(
                    "tr",
                    [
                      _vm._l(_vm.fields, function (field, i) {
                        return _c("th", { key: i }, [
                          _vm._v(" " + _vm._s(field.label) + " "),
                        ])
                      }),
                      _c("th"),
                    ],
                    2
                  ),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.items, function (item) {
                    return _c("tr", { key: item.id }, [
                      _c(
                        "td",
                        { class: "text-" + _vm.getStatusMessage(item).color },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.getStatusMessage(item).title) + " "
                          ),
                        ]
                      ),
                      _c("td", [
                        _vm._v(" " + _vm._s(Math.abs(item.cost)) + " "),
                      ]),
                      _c("td", [
                        _vm._v(
                          " " +
                            _vm._s((item.meta && item.meta.wallet) || "-") +
                            " "
                        ),
                      ]),
                      _c("td", [
                        _vm._v(" " + _vm._s(_vm.getDateMessage(item)) + " "),
                      ]),
                      _c(
                        "td",
                        [
                          _c(
                            "CLoadingButtonCustom",
                            {
                              staticClass: "text-black-50",
                              attrs: {
                                color: "danger",
                                loading: _vm.loading,
                                disabled: _vm.isCancelBlocked(item.status),
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.setStatus(item, "canceled")
                                },
                              },
                            },
                            [
                              _c("CIcon", {
                                staticClass: "mt-0",
                                attrs: { name: "cil-x-circle", size: "sm" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }