<template>
  <div class="d-flex">
    <template v-if="isEditCode">
      <CForm class="d-flex w-100" @submit.prevent="saveCode">
        <CInput
          ref="inputCode"
          class="cinput mb-0 mr-2 w-100"
          :value="code"
          autocomplete="email"
          @input="$emit('update:code', $event)"
        >
          <template #prepend-content>
            <CIcon name="cil-link"/>
          </template>
        </CInput>
        <span @click="saveCode">
        <CLoadingButtonCustom
          color="success"
          class="cbtn shadow-none mr-2"
          :loading="loading"
          :disabled="loading"
        >
          <CIcon v-if="!loading" name="cil-save"/>
        </CLoadingButtonCustom>
      </span>
        <CButton
          color="primary"
          @click="isEditCode = false"
        >
          <CIcon name="cil-reload"/>
        </CButton>
      </CForm>
    </template>
    <template v-else>
      <CInput
        class="cinput mb-0 mr-2 w-100"
        :value="fullUrl"
        disabled
        autocomplete="email"
      >
        <template #prepend-content>
          <CIcon name="cil-link"/>
        </template>
      </CInput>
      <CButton
        class="mr-2"
        color="warning"
        @click="startEdit"
      >
        <CIcon name="cil-pencil"/>
      </CButton>
      <CButton
        color="success"
        @click="copyLink"
      >
        <CIcon name="cil-copy"/>
      </CButton>
    </template>
  </div>
</template>

<script>
import {copyTextToClipboard} from '@/assets/js/plugins/copyText';

export default {
  name: 'EditReferralCode',
  props: {
    code: {
      type: String,
      required: true
    }
  },
  data: () => ({
    isEditCode: false,
    loading: false,
    initCode: ''
  }),
  computed: {
    fullUrl() {
      return document.location.origin + `/?ref=${this.initCode}`/* this.$router.resolve({
        name: 'referral-link',
        params: {
          code: this.initCode
        }
      }).href; */
    }
  },
  beforeMount() {
    this.initCode = this.code;
  },
  methods: {
    saveCode() {
      this.loading = true;
      const code = this.code.toLowerCase()
      this.$store
        .dispatch('user/updateSelf', {referral_code: code})
        .then(() => {
          this.$toastr.success(this.$t('lk.referrals.referral_link_update'));
          this.initCode = code;
          this.isEditCode = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    startEdit() {
      this.isEditCode = true;
      this.$nextTick(() => {
        this.$refs.inputCode.$el.querySelector('input').focus();
      });
    },
    copyLink() {
      copyTextToClipboard(this.fullUrl, (status) => {
        if (status) {
          this.$toastr.success(this.$t('lk.referrals.copy_referral_link'));
        } else {
          this.$toastr.error(this.$t('lk.referrals.copy_referral_link_fail'));
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.cinput /deep/ .form-control, .cbtn {
  height: 2.75rem;
}
</style>
